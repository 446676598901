import './App.css';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

// Components
import Covers from './components/Covers';

function App() {

  return (
    <Grid container className="App">
      <h2 className='nav'>
        <span className='hover-underline-animation'>
          <Link to='/about'>ABOUT</Link>
        </span>
      </h2>
      <Covers />
    </Grid>
  );
}

export default App;