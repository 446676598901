import React from 'react';
// From M-UI
import Grid from '@material-ui/core/Grid';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import GamesIcon from '@material-ui/icons/Games';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const slideEffectDefault = ['', '', ''];

function Covers() {
    const [firstDes, showFirstDes] = React.useState(false);
    const [secondDes, showSecondDes] = React.useState(false);
    const [thirdDes, showThirdDes] = React.useState(false);
    const [slideEffect, setSlideEffect] = React.useState(slideEffectDefault);

    function elementShowUp(order) {
        switch (order) {
            case 'first':
                if (firstDes) {
                    showFirstDes(false);
                }
                else {
                    showFirstDes(true);
                    showSecondDes(false);
                    showThirdDes(false);
                }
                break;
            case 'second':
                if (secondDes) {
                    showSecondDes(false);
                }
                else {
                    showSecondDes(true);
                    showFirstDes(false);
                    showThirdDes(false);
                }
                break;
            case 'third':
                if (thirdDes) {
                    showThirdDes(false);
                }
                else {
                    showThirdDes(true);
                    showFirstDes(false);
                    showSecondDes(false);
                }
                break;
            default:
                showFirstDes(false);
                showSecondDes(false);
                showThirdDes(false);
                break;
        }
    }

    function slideStyleChange(slideState) {
        let newSlideEffect = JSON.parse(JSON.stringify(slideEffect));
        const slideEffectDefault = ['', '', ''];
        newSlideEffect = slideEffectDefault;
        if (slideState === 1) {
            newSlideEffect[1] = 'slideL2R mediumZIndex mediumDelay';
            newSlideEffect[2] = 'slideL2R lowZIndex highDelay';
        }
        else if (slideState === 2) {
            newSlideEffect[0] = 'slideR2L33 lowZIndex mediumDelay';
            newSlideEffect[2] = 'slideL2R33 lowZIndex highDelay';
        }
        else if (slideState === 3) {
            newSlideEffect[0] = 'slideR2L lowZIndex highDelay';
            newSlideEffect[1] = 'slideR2L mediumZIndex mediumDelay';
        }
        setSlideEffect(newSlideEffect);
    }

    return (
        <Grid container className='covers'>
            {
                secondDes || thirdDes ? null :
                    <>
                        <Grid item md={4} sm={12}
                            style={{ cursor: 'pointer' }}
                            className={firstDes ? 'guessWhat pointerCursor' : 'guessWhat ' + slideEffect[0]}
                            onClick={() => { elementShowUp('first'); slideStyleChange(1) }}
                        >
                            <LockOpenIcon fontSize='large' />
                            <h3>guesswut.com</h3>
                            <span>COMING SOON...</span>
                        </Grid>
                        {
                            firstDes ?
                                <>
                                    <Grid item md={4} sm={12} className={'guessWhat ' + slideEffect[1]}>
                                        <span>A WEBSITE,</span>
                                    </Grid>
                                    <Grid item md={4} sm={12} className={'guessWhat ' + slideEffect[2]}>
                                        <span>TO INTERACT WITH.</span>
                                    </Grid>
                                </> : null
                        }
                    </>
            }

            {
                secondDes ?
                    <>
                        <Grid item md={4} sm={12} className={'yf ' + slideEffect[0]}>
                            <span>A GAME,</span>
                        </Grid>
                    </> : null
            }
            {
                firstDes || thirdDes ? null :
                    <>
                        <Grid item md={4} sm={12}
                            style={{ cursor: 'pointer' }}
                            className={secondDes ? 'yf pointerCursor' : 'yf ' + slideEffect[1]}
                            onClick={() => { elementShowUp('second'); slideStyleChange(2) }}>
                            <GamesIcon fontSize='large' />
                            <h3>YENING'S FANTASY</h3>
                            <span>AFTER COMING SOON......</span>
                        </Grid>
                    </>
            }
            {
                secondDes ?
                    <>
                        <Grid item md={4} sm={12} className={'yf ' + slideEffect[2]}>
                            <span>TO EXPRESS MYSELF.</span>
                        </Grid>
                    </> : null
            }

            {
                firstDes || secondDes ? null :
                    <>
                        {
                            thirdDes ?
                                <>
                                    <Grid item md={4} sm={12} className={'unknown ' + slideEffect[0]}>
                                        <span>A CARRIER,</span>
                                    </Grid>
                                    <Grid item md={4} sm={12} className={'unknown ' + slideEffect[1]}>
                                        <span>TO BE DETERMINED.</span>
                                    </Grid>
                                </> : null
                        }
                        <Grid item md={4} sm={12}
                            style={{ cursor: 'pointer' }}
                            className={thirdDes ? 'unknown pointerCursor' : 'unknown ' + slideEffect[2]}
                            onClick={() => { elementShowUp('third'); slideStyleChange(3) }}>
                            <HelpOutlineIcon fontSize='large' />
                            <h3>Project: Unknown</h3>
                            <span>EVER COMING?</span>
                        </Grid>

                    </>
            }
        </Grid>
    );
}

export default Covers;