import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';

// From M-UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
// import EmailIcon from '@material-ui/icons/Email';

function About() {
    const [open, setOpen] = React.useState(false);

    return (
        <Grid container>
            <h2 className='nav'>
                <span className='hover-underline-animation'>
                    <Link to='/'>HOME</Link>
                </span>
            </h2>
            <Grid container className='aboutPage'>
                <Grid item lg={4} sm={12} className='aboutWeb'>
                    <h1>ABOUT</h1>
                    <h5>THE WEBSITE</h5>
                </Grid>

                <Grid item lg={8} sm={12} className='paragraph'>
                    <p>
                        It is a simple website. It should appear at some point.<br />
                        Yening said "Let there be website", so there was the website.<br /><br />
                        Actually, the idea of building this website came up long time ago.
                        However, I was somehow too lazy to do it, and I had no clues what I should put on the pages.<br />
                        Gladly, I now know what I should do for my website.<br />
                        Basically, this website will show all projects that I am doing or I plan to do.<br />
                        But most importantly, this website is an evidence to prove I was existed, I had done something, and I left something.
                    </p>
                </Grid>

                <Grid item lg={4} sm={12} className='aboutMe'>
                    <h1>ABOUT</h1>
                    <h5>YENING WANG</h5>
                </Grid>

                <Grid item lg={8} sm={12} className='paragraph'>
                    <p>
                        A programmer. <br />
                        A designer. <br />
                        A husband. <br />
                        A player. <br />
                        A happy guy. <br />
                        ... <br /><br />

                        There are many characters I am currently playing.<br />
                        But if I am going to use only one word to describe me, '<b>thinker</b>' will be the one,
                        though I do not think as involved as The Thinker. <br />
                        By thinking a lot, I found my life and this world are more interesting and attractive. <br /><br />

                        What's more, I love drinking Coca-Cola.<br />
                        I had done a great experiment about Coca-Cola, which I prepared 2 bottles of coke, one was Coca-Cola, another one was Pepsi. <br />
                        I then covered my eyes and tasted them to tell which is which, the order was messed up before. <br />
                        Of course, as a huge fan of Coca-Cola, I succeeded. <br /><br />

                        Oh yeah, one more thing.
                        My favorite ice cream is called <span
                            onClick={() => setOpen(true)}
                            className="moonMistDes"
                        >
                            Moon Mist
                        </span> which is very popular in Nova Scotia. <br />
                        I hope Island Farm can re-produce it again in BC.
                    </p>



                </Grid>
                <Grid item lg={4} sm={12} className='contactInfo'>
                    <h1>CONTACT</h1>
                    <h5>INFORMATION</h5>
                </Grid>

                <Grid item lg={8} sm={12} className='paragraph'>
                    <h3 style={{ marginBottom: 0 }}>ELECTRONIC MAIL ADDRESS:</h3>
                    <div className='emailSpan'>
                        <span
                            onClick={() => window.open("mailto:yeningwang@yeningwang.com")}
                            style={{ cursor: 'pointer' }}
                        > yeningwang @yeningwang.com</span>
                    </div>
                    <h3 style={{ marginBottom: 0 }}>FOLLOW ME FOR MORE DELAYED INFORMATION: </h3>
                    <span className='webIcons'>
                        <LinkedInIcon onClick={() => window.open("https://www.linkedin.com/in/yening-wang/")} fontSize='large' />
                        <InstagramIcon onClick={() => window.open("https://www.instagram.com/fantaxy1001/")} fontSize='large' />
                    </span>
                </Grid>
                <Grid item lg={12} className='footer'>
                    <h3 style={{marginBottom: 0}}>POWERED by YENING WANG</h3>
                    <h3 style={{marginTop: 0}}>COPYRIGHT © {new Date().getFullYear()} YENING WANG</h3>
                </Grid>
            </Grid>



            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"About Moon Mist Ice Cream"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        "Moon Mist is an eye-catching, mouthwatering blend of three distinct flavors of ice cream: grape, banana, and bubblegum/blue raspberry.
                        It’s an unusual combo, to say the least.<br /><br />
                        One theory says that a flavor company shopped it around in the early 1980s, as Nova Scotian dairies started churning out the tricolored variety at roughly the same time.
                        The result was a hit in Canada’s Atlantic provinces, where it’s the most popular flavor of ice cream."
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </Grid>
    );
}

export default About;